<template>
  <v-container class="pt-0 mt-0">
    <CheckoutLayout>
      <template v-slot:default>
        <template v-if="$route.name !== 'advica-payment-status'">
          <v-row no-gutters class="mt-4 mt-md-2 px-5 px-md-0">
            <v-col cols="12" md="4">
              <h1 class="mb-0 advica-title">
                {{ $t('advica.booking') }}
              </h1>
            </v-col>
            <v-col cols="12" md="8" class="mt-2 mt-lg-3 pl-lg-10">
              <AdvicaProgressIndicatorContainer />
            </v-col>
          </v-row>
          <v-divider class="mb-6 mb-md-3 mt-1 mt-md-0 mx-3 mx-md-0" />
        </template>
        <router-view></router-view>
      </template>
      <template v-slot:summary>
        <template v-if="$vuetify.breakpoint.smAndDown">
          <AdvicaSummaryMobile />
        </template>

        <template v-else>
          <AdvicaSummary />
        </template>
      </template>
    </CheckoutLayout>
  </v-container>
</template>

<script>
import CheckoutLayout from '@/modules/product/features/CheckoutLayout/CheckoutLayout';
import AdvicaSummary from '@/modules/product/advica/features/AdvicaOrderSummary/AdvicaSummary';
import AdvicaProgressIndicatorContainer from '@/modules/product/advica/features/AdvicaForm/AdvicaProgressIndicatorContainer';
import AdvicaSummaryMobile from '@/modules/product/advica/features/AdvicaOrderSummary/AdvicaSummaryMobile';

export default {
  name: 'AdvicaCheckoutPage',
  components: {
    CheckoutLayout,
    AdvicaSummary,
    AdvicaSummaryMobile,
    AdvicaProgressIndicatorContainer,
  },
};
</script>

<style lang="scss" scoped>
.advica-title {
  @include font-size(20, 150, 600);

  @media (min-width: map-get($grid-breakpoints, sm)) {
    @include font-size(30, 150, 600);
  }
}
</style>
