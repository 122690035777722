<script>
import AdvicaStoreModule from '@/modules/product/advica/store/index';

export default {
  name: 'AdvicaStoreMixins',
  created() {
    this.registerStore();
  },
  data() {
    return {
      storeModuleName: 'advica-store',
    };
  },
  methods: {
    registerStore() {
      const store = this.$store;
      if (!AdvicaStoreModule[`registered-${this.storeModuleName}`]) {
        store.registerModule(this.storeModuleName, AdvicaStoreModule, {
          preserveState: !!store.state[this.storeModuleName],
        });
        AdvicaStoreModule[`registered-${this.storeModuleName}`] = true;
      }
    },
  },
};
</script>
