<template>
  <ProgressIndicator :steps="steps" :show-steps="false" />
</template>

<script>
import ProgressIndicator from '@/modules/product/features/ProgressIndicator/ProgressIndicator';
export default {
  name: 'AdvicaProgressIndicatorContainer',
  components: { ProgressIndicator },
  data() {
    return {
      steps: [
        {
          name: this.$t('product.steps.names.traveller_details'),
          status: 'ACTIVE',
          tag: 'advica-forms',
        },
        {
          name: this.$t('product.steps.names.review_payment'),
          status: 'PENDING',
          tag: 'advica-payment',
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        const activeIndex = this.steps.findIndex(
          (step) => step.tag === to.name
        );
        if (activeIndex < 0) {
          throw Error(
            this.$t('product.steps.progress_error', {
              name: to.name,
            })
          );
        } else {
          this.updateStepsStatus(activeIndex);
        }
      },
    },
  },
  methods: {
    updateStepsStatus(activeIndex) {
      this.setStatus({
        status: 'DONE',
        fromIndex: 0,
        toIndex: activeIndex,
      });
      this.setStatus({
        status: 'ACTIVE',
        fromIndex: activeIndex,
        toIndex: activeIndex + 1,
      });
      this.setStatus({
        status: 'PENDING',
        fromIndex: activeIndex + 1,
        toIndex: this.steps.length,
      });
    },
    setStatus({ status = 'PENDING', fromIndex = 0, toIndex = 0 } = {}) {
      for (fromIndex; fromIndex < toIndex; fromIndex++) {
        this.steps[fromIndex].status = status;
      }
    },
  },
};
</script>

<style scoped></style>
