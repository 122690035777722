<template>
  <v-col cols="12" class="pa-0">
    <v-row no-gutters class="mb-3">
      <v-col no-gutters>
        <div class="size14-weight700 d-flex align-start pb-1">
          <div class="d-flex justify-center options-result-icon">
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="
              M14.7142 15.8806L11.1783 19.4164C11.0237 19.5712 10.8401 19.694 10.638
              19.7778C10.4358 19.8616 10.2192 19.9047 10.0004 19.9047C9.78162 19.9047
              9.56498 19.8616 9.36286 19.7778C9.16075 19.694 8.97713 19.5712 8.8225
              19.4164L5.28583 15.8806C4.35352 14.9482 3.71861 13.7603 3.4614 12.4671C3.2042
              11.1739 3.33624 9.83352 3.84083 8.61536C4.34543 7.39721 5.19991 6.35604
              6.29623 5.62351C7.39255 4.89098 8.68147 4.5 10 4.5C11.3185 4.5 12.6074
              4.89098 13.7038 5.62351C14.8001 6.35604 15.6546 7.39721 16.1592 8.61536C16.6638
              9.83352 16.7958 11.1739 16.5386 12.4671C16.2814 13.7603 15.6465 14.9482
              14.7142 15.8806V15.8806Z
            "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="
              M12.5 11.167C12.5 11.83 12.2366 12.4659 11.7678 12.9348C11.2989 13.4036
              10.663 13.667 10 13.667C9.33696 13.667 8.70107 13.4036 8.23223 12.9348C7.76339
              12.4659 7.5 11.83 7.5 11.167C7.5 10.504 7.76339 9.86807 8.23223 9.39922C8.70107
              8.93038 9.33696 8.66699 10 8.66699C10.663 8.66699 11.2989 8.93038 11.7678
              9.39922C12.2366 9.86807 12.5 10.504 12.5 11.167V11.167Z
            "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span class="ml-2 size16-weight400">
            <b>{{ $t('advica.from') }} </b>
            {{ selectedDateRange[0] | dateFormatting }},
          </span>
        </div>

        <div class="size14-weight700 d-flex align-start pb-1">
          <div class="d-flex justify-center options-result-icon">
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="
              M14.7142 15.8806L11.1783 19.4164C11.0237 19.5712 10.8401 19.694 10.638
              19.7778C10.4358 19.8616 10.2192 19.9047 10.0004 19.9047C9.78162 19.9047
              9.56498 19.8616 9.36286 19.7778C9.16075 19.694 8.97713 19.5712 8.8225
              19.4164L5.28583 15.8806C4.35352 14.9482 3.71861 13.7603 3.4614 12.4671C3.2042
              11.1739 3.33624 9.83352 3.84083 8.61536C4.34543 7.39721 5.19991 6.35604
              6.29623 5.62351C7.39255 4.89098 8.68147 4.5 10 4.5C11.3185 4.5 12.6074
              4.89098 13.7038 5.62351C14.8001 6.35604 15.6546 7.39721 16.1592 8.61536C16.6638
              9.83352 16.7958 11.1739 16.5386 12.4671C16.2814 13.7603 15.6465 14.9482
              14.7142 15.8806V15.8806Z
            "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="
              M12.5 11.167C12.5 11.83 12.2366 12.4659 11.7678 12.9348C11.2989 13.4036
              10.663 13.667 10 13.667C9.33696 13.667 8.70107 13.4036 8.23223 12.9348C7.76339
              12.4659 7.5 11.83 7.5 11.167C7.5 10.504 7.76339 9.86807 8.23223 9.39922C8.70107
              8.93038 9.33696 8.66699 10 8.66699C10.663 8.66699 11.2989 8.93038 11.7678
              9.39922C12.2366 9.86807 12.5 10.504 12.5 11.167V11.167Z
            "
                :stroke="$vuetify.theme.themes.light.grey9"
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span class="ml-2 size16-weight400">
            <b>{{ $t('advica.to') }} </b>
            {{ selectedDateRange[1] | dateFormatting }}
          </span>
        </div>

        <v-row no-gutters v-if="hasPerson">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="
              M9 2.12833C9.44787 1.62059 10.0397 1.26126 10.6968 1.09816C11.354
              0.935066 12.0451 0.975936 12.6785 1.21533C13.3118 1.45473 13.8572
              1.88131 14.2421 2.4383C14.627 2.99529 14.8332 3.65629 14.8332
              4.33333C14.8332 5.01038 14.627 5.67138 14.2421 6.22837C13.8572
              6.78536 13.3118 7.21193 12.6785 7.45133C12.0451 7.69073 11.354
              7.7316 10.6968 7.56851C10.0397 7.40541 9.44787 7.04608 9 6.53833M11.5
              16H1.5V15.1667C1.5 13.8406 2.02678 12.5688 2.96447 11.6311C3.90215
              10.6935 5.17392 10.1667 6.5 10.1667C7.82608 10.1667 9.09785 10.6935
              10.0355 11.6311C10.9732 12.5688 11.5 13.8406 11.5 15.1667V16ZM11.5
              16H16.5V15.1667C16.5001 14.2889 16.2692 13.4266 15.8304 12.6664C15.3916
              11.9062 14.7604 11.2749 14.0003 10.836C13.2402 10.3971 12.3779 10.166
              11.5002 10.166C10.6224 10.1659 9.76015 10.397 9 10.8358M9.83333
              4.33333C9.83333 5.21739 9.48214 6.06524 8.85702 6.69036C8.2319 7.31548
              7.38405 7.66667 6.5 7.66667C5.61594 7.66667 4.7681 7.31548 4.14298
              6.69036C3.51786 6.06524 3.16667 5.21739 3.16667 4.33333C3.16667 3.44928
              3.51786 2.60143 4.14298 1.97631C4.7681 1.35119 5.61594 1 6.5 1C7.38405
              1 8.2319 1.35119 8.85702 1.97631C9.48214 2.60143 9.83333 3.44928 9.83333 4.33333Z
            "
              style="stroke: var(--v-grey8-base);"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="px-3" v-if="getPasses && getPasses.length > 0">
            <span
              class="options-result-travelers"
              v-for="(traveler, index) in travelersCount"
              :key="index"
            >
              {{ traveler.id }}: {{ traveler.value }}
            </span>
          </div>
          <span v-else class="ml-2">{{ $t('advica.no_data') }}</span>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="adultsCount > 0">
      <v-divider />
      <div class="d-flex justify-space-between my-4 pl-2">
        <div>
          <span class="adv-people-count size16-weight700">
            {{ adultsCount }}
          </span>
          {{
            $t('advica.passes.adult_price', {
              price: price.value,
              days: getTotalDays,
            })
          }}
        </div>
        <div class="justify-end d-flex">
          {{ priceAdult | currency(price.currency) }}
        </div>
      </div>
    </template>
    <template v-if="childrenCount > 0">
      <v-divider class="my-2" />
      <div class="d-flex justify-space-between my-4">
        <div>
          <span class="adv-people-count size16-weight700">
            {{ childrenCount }}
          </span>
          {{
            $t('advica.passes.children_price', {
              price: price.value,
              days: getTotalDays,
            })
          }}
        </div>
        <div class="justify-end d-flex">
          {{ priceChild | currency(price.currency) }}
        </div>
      </div>
    </template>
    <template v-if="discount">
      <v-divider class="my-2" />
      <v-row no-gutters class="mt-3 justify-space-between">
        <div class="subtitle-1">
          {{ $t('advica.code') }} {{ discount.label }}
        </div>
        <div class="subtitle-1">
          {{ discount.discountAmount | currency(price.currency) }}
        </div>
      </v-row>
    </template>
    <v-divider class="my-3" v-if="hasDate || hasPerson" />
  </v-col>
</template>

<script>
import moment from 'moment';
import AdvicaUserFormStoreMixins from '@/modules/product/advica/mixins/AdvicaUserFormStoreMixins';

export default {
  name: 'AdvicaOrderSelections',
  mixins: [AdvicaUserFormStoreMixins],
  props: {
    price: {
      type: Object,
      default: () => [],
    },
    selectedDate: {
      type: Array,
      default: () => [],
    },
    discount: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      calendarIcon: require('@/assets/calendar-filled.svg'),
      userIcon: require('@/assets/user-filled.svg'),
    };
  },
  filters: {
    dateFormatting(val) {
      return `${moment(val).format('MMM DD')}, ${moment(val).format('YYYY')}`;
    },
  },
  methods: {
    isEmptyOrNull(val) {
      return val === null || val === '';
    },
    totalDays() {
      let firstDate = moment(this.selectedDateRange[0]);
      let secondDate = moment(this.selectedDateRange[1]);

      let dayDiff = secondDate.diff(firstDate, 'days') + 1;
      return `(${dayDiff} Days)`;
    },
  },
  computed: {
    priceAdult() {
      let price = this.adultsCount * this.price.value * this.getTotalDays;
      return parseFloat(price).toFixed(2);
    },
    priceChild() {
      let price = this.childrenCount * this.price.value * this.getTotalDays;
      return parseFloat(price).toFixed(2);
    },
    hasDate() {
      return !this.isEmptyOrNull(this.selectedDateRange);
    },
    hasPerson() {
      return !this.selectedPasses.length == 0;
    },
    getTotalDays() {
      if (!this.isEmptyOrNull(this.selectedDateRange)) {
        let firstDate = moment(this.selectedDateRange[0]);
        let secondDate = moment(this.selectedDateRange[1]);

        return secondDate.diff(firstDate, 'days') + 1;
      } else {
        return 1;
      }
    },
    travelersCount() {
      let travelers = [];
      this.getPasses.forEach((item) => {
        let newItem = {
          id: `${item.id[0].toUpperCase()}${item.id.slice(1)}`,
          totalPrice: item.totalPrice,
          value: item.value,
        };

        travelers.push(newItem);
      });

      return travelers;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-col {
  max-height: 24px;
  max-width: 24px;
}

.bounceInRight-enter-active {
  animation: bounceInRight 0.5s;
}
.bounceInRight-leave-active {
  animation: bounceInRight 0.5s reverse;
}
.adv-people-count {
  margin-right: 7px;
}

::v-deep.options {
  &-result {
    & > div {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-icon {
      padding-top: 2px;
    }

    &-travelers {
      position: relative;

      &::after {
        content: '/';
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
</style>
