<template>
  <v-container fluid class="pa-0 ma-0 align-center">
    <v-row
      :no-gutters="$vuetify.breakpoint.smAndDown"
      v-if="showSteps"
      class="pt-1 mb-3 mx-0"
    >
      <div
        :class="[
          'text-uppercase',
          'font-weight-black',
          { 'font-mobile': $vuetify.breakpoint.smAndDown },
        ]"
      >
        {{ $t('product.indicator_step') }} {{ currentStep }} / {{ totalSteps }}
      </div>
    </v-row>

    <div class="d-flex flex-wrap justify-start justify-md-end">
      <v-row no-gutters v-for="(step, i) in steps" :key="i" class="steps">
        <v-col cols="12" class="pa-0 d-flex">
          <v-row no-gutters class="pa-0 d-flex align-center">
            <ProgressIndicatorBooking :step="step" />
            <span
              class="pl-1 size14-weight400"
              :class="{ 'font-weight-black': step.status === 'ACTIVE' }"
            >
              {{ step.name }}
            </span>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import ProgressIndicatorBooking from './ProgressIndicatorBooking';
export default {
  name: 'ProgressIndicator',
  components: { ProgressIndicatorBooking },
  props: {
    showSteps: {
      type: Boolean,
      default: true,
    },
    steps: {
      default: function() {
        return [
          {
            name: 'Availability',
            status: 'DONE',
          },
          {
            name: 'booking-traveler',
            status: 'ACTIVE',
          },
          {
            name: 'booking-payment',
            status: 'PENDING',
          },
        ];
      },
      type: Array,
    },
  },
  computed: {
    totalSteps() {
      return this.steps.length;
    },
    currentStep() {
      let current = 0;
      for (let step of this.steps) {
        if (step.status === 'DONE' || step.status === 'ACTIVE') {
          current++;
        } else {
          break;
        }
      }
      return current;
    },
  },
};
</script>

<style lang="scss" scoped>
.steps {
  position: relative;
  flex: 0 0 auto;
  padding: 5px 10px 5px 20px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: 10px;
    height: 1px;
    background: #b8c1d1;
  }

  &:first-child {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  &:last-child {
    padding-right: 0;
  }
}
.seperator {
  width: 20px;
  height: 2px;
  background: #e8e6e1;
  border-radius: 2px;
}
.seperator-mobile {
  width: 20px;
  height: 2px;
  border-radius: 2px;
  background: #e8e6e1;
}
.font-mobile {
  font-size: 12px;
}
.step-mobile {
  padding-bottom: 6px;
}
</style>
