<template>
  <v-container class="px-3">
    <v-row no-gutters class="mt-1 flex-column">
      <p class="size18-weight600 mb-3">
        {{ $t('booking_details') }}
      </p>
      <v-divider />
    </v-row>
    <div class="d-flex justify-space-between align-center my-3">
      <span class="adv-block-title">
        <template v-if="advicaDetails.value">
          {{ advicaDetails.value.title }}
        </template>
        <template v-else>
          {{ $t('advica.summary.title') }}
        </template>
      </span>
      <v-img
        :alt="advicaDetails.value.title"
        v-if="advicaDetails.value.thumbnail"
        class="adv-block-img"
        contain
        position="right"
        :src="advicaDetails.value.thumbnail"
      />
    </div>
    <AdvicaOrderSelections :price="price" :discount="discount" />
    <div class="d-flex justify-space-between align-center">
      <div class="size16-weight400">{{ getTotalText }}</div>
      <div class="size20-weight700">
        {{ getTotalPrice(discount) | currency(getBaseCurrency) }}
        {{ getBaseCurrency }}
      </div>
    </div>
  </v-container>
</template>

<script>
import AdvicaOrderSelections from '@/modules/product/advica/features/AdvicaOrderSummary/AdvicaOrderSelections';
import LoyaltyMixins from '@/modules/common/mixins/LoyaltyMixins';
import AdvicaUserFormStoreMixins from '@/modules/product/advica/mixins/AdvicaUserFormStoreMixins';
import AdvicaDetailsMixins from '@/modules/product/advica/mixins/AdvicaDetailsMixins';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins';
export default {
  name: 'AdvicaSummary',
  mixins: [
    LoyaltyMixins,
    AdvicaUserFormStoreMixins,
    AdvicaDetailsMixins,
    ProductCheckoutMixins,
  ],
  components: { AdvicaOrderSelections },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    price() {
      return {
        value: this.getStartingPrice,
        currency: this.getBaseCurrency,
      };
    },
    getTotalText() {
      if (this.selectedDateRange || this.selectedPasses.length != 0) {
        return `${this.$t('advica.summary.total_price')}`;
      } else {
        return `${this.$t('advica.summary.from')}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  filter: blur(3px);
}
.image {
  flex: 1 0 auto;
  min-width: 100%;
  min-height: 100%;
}
.title {
  @include font-size(20, 27, 600);
  text-transform: capitalize;
  color: #27241d;
}
.total-price {
  @include font-size(20, 32, 700);
  color: var(--v-grey9-base);
}
.total-text {
  @include font-size(16, 24, 400);
  color: var(--v-grey9-base);
}
.notes {
  font-size: 14px;
}

.info-cta {
  user-select: none;
}

.adv-block-title {
  @include font-size(18, 32, 600);
  margin-bottom: 0;
}

.adv-block-img {
  width: 100px;
  height: 100px;

  @media (min-width: map-get($grid-breakpoints, lg)) {
    height: auto;
    border: 1px solid #cfd5e0;
    box-shadow: 0px 3px 8px rgba(88, 98, 113, 0.15);
  }
}
</style>
