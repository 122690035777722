<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header hide-actions class="adv-panel-header px-3">
        <v-row no-gutters class="justify-space-between align-center">
          <div class="adv-panel-title size14-weight700">
            {{ $t('show_booking_details') }}
          </div>
          <div class="total-price mr-1">
            {{ getTotalPrice | currency(getBaseCurrency) }}
            {{ getBaseCurrency }}
          </div>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-container class="px-3">
          <v-row no-gutters class="mt-1 flex-column">
            <p class="size18-weight600 mb-3">
              {{ $t('booking_details') }}
            </p>
            <v-divider></v-divider>
          </v-row>
          <div
            v-if="advicaDetails.value"
            class="d-flex justify-space-between align-center my-3"
          >
            <span class="adv-block-title">
              <template v-if="advicaDetails.value">
                {{ advicaDetails.value.title }}
              </template>
              <template v-else>
                {{ $t('advica.summary.title') }}
              </template>
            </span>
            <v-img
              :alt="advicaDetails.value.title"
              v-if="advicaDetails.value.thumbnail"
              class="adv-block-img"
              contain
              :aspectRatio="1.23"
              position="right"
              :src="advicaDetails.value.thumbnail"
            />
          </div>
          <AdvicaOrderSelections :price="price" />
          <div class="d-flex justify-space-between align-center">
            <div class="total-text">{{ getTotalText }}</div>
            <div class="total-price">
              {{ getTotalPrice | currency(getBaseCurrency) }}
              {{ getBaseCurrency }}
            </div>
          </div>
        </v-container>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import AdvicaOrderSelections from '@/modules/product/advica/features/AdvicaOrderSummary/AdvicaOrderSelections';
import LoyaltyMixins from '@/modules/common/mixins/LoyaltyMixins';
import AdvicaUserFormStoreMixins from '@/modules/product/advica/mixins/AdvicaUserFormStoreMixins';
import AdvicaDetailsMixins from '@/modules/product/advica/mixins/AdvicaDetailsMixins';
import moment from 'moment';
export default {
  name: 'AdvicaSummaryMobile',
  mixins: [LoyaltyMixins, AdvicaUserFormStoreMixins, AdvicaDetailsMixins],
  components: { AdvicaOrderSelections },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    price() {
      return {
        value: this.getStartingPrice,
        currency: this.getBaseCurrency,
      };
    },
    getTotalText() {
      if (this.selectedDateRange || this.selectedPasses.length != 0) {
        return `${this.$t('advica.summary.total_price')} (${
          this.getBaseCurrency
        })`;
      } else {
        return `${this.$t('advica.summary.from')} (${this.getBaseCurrency})`;
      }
    },
    getTotalPrice() {
      if (this.selectedDateRange || this.selectedPasses.length != 0) {
        let firstDate = moment();
        let secondDate = moment();

        if (this.selectedDateRange) {
          firstDate = moment(this.selectedDateRange[0]);
          secondDate = moment(this.selectedDateRange[1]);
        }

        let getTotalDays = secondDate.diff(firstDate, 'days') + 1 || 1;

        let totalPrice =
          this.getStartingPrice *
          getTotalDays *
          (this.adultsCount + this.childrenCount);

        return parseFloat(totalPrice).toFixed(2);
      } else {
        return parseFloat(this.getStartingPrice).toFixed(2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  filter: blur(3px);
}
.image {
  flex: 1 0 auto;
  min-width: 100%;
  min-height: 100%;
}

.title {
  @include font-size(20, 27, 600);
  text-transform: capitalize;
  color: #27241d;
}

.total-price {
  @include font-size(20, 32, 700);
  color: var(--v-grey9-base);
}

.total-text {
  @include font-size(16, 24, 400);
  color: var(--v-grey9-base);
}

.notes {
  font-size: 14px;
}

.info-cta {
  user-select: none;
}

.adv-block-title {
  @include font-size(18, 32, 600);
  margin-bottom: 0;
}

.adv-block-img {
  width: 100px;
  border: 1px solid #cfd5e0;
  @media (min-width: map-get($grid-breakpoints, lg)) {
    height: auto;
    border: 1px solid #cfd5e0;
    box-shadow: 0px 3px 8px rgba(88, 98, 113, 0.15);
  }
}

.adv-panel {
  &-header {
    background-color: var(--v-grey1-base);
  }

  &-title {
    color: var(--v-primary-base);
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 4px;
      right: -17px;
      width: 10px;
      height: 10px;
      border: 2px solid var(--v-primary-base);
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
    }
  }
}
</style>
