<script>
import AdvicaStoreMixins from '@/modules/product/advica/mixins/AdvicaStoreMixins';
import moment from 'moment';
import { advancedCurrencyFilter } from '@/core/filters/currency';

export default {
  name: 'AdvicaUserFormStoreMixins',
  mixins: [AdvicaStoreMixins],
  methods: {
    setDateRange(dateRange) {
      this.$store.dispatch(
        `${this.storeModuleName}/setUserSelectedDateRange`,
        dateRange
      );
    },
    removeDateRange() {
      this.$store.dispatch(
        `${this.storeModuleName}/removeUserSelectedDateRange`
      );
    },
    setPasses(passes) {
      this.$store.dispatch(`${this.storeModuleName}/setUserPasses`, passes);
    },
    setContacts(contacts) {
      this.$store.dispatch(`${this.storeModuleName}/setUserContacts`, contacts);
    },
    setUSConfirmation(isConfirmed) {
      this.$store.dispatch(
        `${this.storeModuleName}/setUserUSDestinationConfirmed`,
        isConfirmed
      );
    },
    getTotalPrice(discount) {
      if (!discount) {
        if (this.selectedDateRange || this.selectedPasses.length != 0) {
          let firstDate = moment();
          let secondDate = moment();

          if (this.selectedDateRange) {
            firstDate = moment(this.selectedDateRange[0]);
            secondDate = moment(this.selectedDateRange[1]);
          }

          let getTotalDays = secondDate.diff(firstDate, 'days') + 1 || 1;

          let totalPrice =
            this.getStartingPrice *
            getTotalDays *
            (this.adultsCount + this.childrenCount);

          return parseFloat(totalPrice).toFixed(2);
        } else {
          return parseFloat(this.getStartingPrice).toFixed(2);
        }
      }

      return discount.finalTotal;
    },
  },
  computed: {
    userForm() {
      return this.$store.state[this.storeModuleName].userForm;
    },
    selectedDateRange() {
      if (this.userForm.selectedDateRange) {
        let dateArray = [];
        let firstDate = this.userForm.selectedDateRange[0];
        let secondDate = this.userForm.selectedDateRange[1];

        if (firstDate > secondDate) {
          firstDate = this.userForm.selectedDateRange[1];
          secondDate = this.userForm.selectedDateRange[0];
        }

        dateArray.push(firstDate, secondDate);

        return dateArray;
      } else {
        return null;
      }
    },
    selectedPasses() {
      return this.userForm.selectedPasses;
    },
    contacts() {
      return this.userForm.contacts;
    },
    USDestinationConfirmed() {
      return this.userForm.USDestinationConfirmed;
    },
    adultsCount() {
      return this.$store.getters[`${this.storeModuleName}/adultsPassesCount`];
    },
    childrenCount() {
      return this.$store.getters[`${this.storeModuleName}/childrenPassesCount`];
    },
    getPasses() {
      return this.$store.getters[`${this.storeModuleName}/getAllPasses`];
    },
    priceText() {
      const filterOptions = {
        style: 'currency',
        currency: this.getBaseCurrency,
        maximumSignificantDigits: 3,
      };
      return advancedCurrencyFilter(this.getTotalPrice(), filterOptions);
    },
  },
};
</script>
