<template>
  <div class="icon-wrapper">
    <template v-if="step.name == $t('product.steps.names.availability')">
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
            M5.66667 4.33333V1V4.33333ZM12.3333 4.33333V1V4.33333ZM4.83333 7.66667H13.1667H4.83333ZM3.16667
            16H14.8333C15.2754 16 15.6993 15.8244 16.0118 15.5118C16.3244 15.1993 16.5 14.7754 16.5 14.3333V4.33333C16.5
            3.89131 16.3244 3.46738 16.0118 3.15482C15.6993 2.84226 15.2754 2.66667 14.8333 2.66667H3.16667C2.72464
            2.66667 2.30072 2.84226 1.98816 3.15482C1.67559 3.46738 1.5 3.89131 1.5 4.33333V14.3333C1.5 14.7754
            1.67559 15.1993 1.98816 15.5118C2.30072 15.8244 2.72464 16 3.16667 16Z
          "
          :stroke="
            isActive ? $vuetify.theme.themes.light.primary.base : '#858F9D'
          "
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
    <template v-if="step.name == $t('product.steps.names.Traveler')">
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
            M9 2.62833C9.44787 2.12059 10.0397 1.76126 10.6968 1.59816C11.354 1.43507 12.0451
            1.47594 12.6785 1.71533C13.3118 1.95473 13.8572 2.38131 14.2421 2.9383C14.627 3.49529
            14.8332 4.15629 14.8332 4.83333C14.8332 5.51038 14.627 6.17138 14.2421 6.72837C13.8572
            7.28536 13.3118 7.71193 12.6785 7.95133C12.0451 8.19073 11.354 8.2316 10.6968 8.06851C10.0397
            7.90541 9.44787 7.54608 9 7.03833M11.5 16.5H1.5V15.6667C1.5 14.3406 2.02678 13.0688 2.96447
            12.1311C3.90215 11.1935 5.17392 10.6667 6.5 10.6667C7.82608 10.6667 9.09785 11.1935 10.0355
            12.1311C10.9732 13.0688 11.5 14.3406 11.5 15.6667V16.5ZM11.5 16.5H16.5V15.6667C16.5001 14.7889
            16.2692 13.9266 15.8304 13.1664C15.3916 12.4062 14.7604 11.7749 14.0003 11.336C13.2402 10.8971
            12.3779 10.666 11.5002 10.666C10.6224 10.6659 9.76015 10.897 9 11.3358M9.83333 4.83333C9.83333
            5.71739 9.48214 6.56524 8.85702 7.19036C8.2319 7.81548 7.38405 8.16667 6.5 8.16667C5.61594
            8.16667 4.7681 7.81548 4.14298 7.19036C3.51786 6.56524 3.16667 5.71739 3.16667 4.83333C3.16667
            3.94928 3.51786 3.10143 4.14298 2.47631C4.7681 1.85119 5.61594 1.5 6.5 1.5C7.38405 1.5 8.2319
            1.85119 8.85702 2.47631C9.48214 3.10143 9.83333 3.94928 9.83333 4.83333Z
          "
          :stroke="
            isActive ? $vuetify.theme.themes.light.primary.base : '#858F9D'
          "
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
    <template v-if="step.name == $t('product.steps.names.payment')">
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
            M9 5.16667C7.61917 5.16667 6.5 5.9125 6.5 6.83333C6.5 7.75417 7.61917 8.5 9 8.5C10.3808 8.5
            11.5 9.24583 11.5 10.1667C11.5 11.0875 10.3808 11.8333 9 11.8333V5.16667ZM9 5.16667C9.925
            5.16667 10.7333 5.50167 11.1658 6L9 5.16667ZM9 5.16667V4.33333V5.16667ZM9 5.16667V11.8333V5.16667ZM9
            11.8333V12.6667V11.8333ZM9 11.8333C8.075 11.8333 7.26667 11.4983 6.83417 11L9 11.8333ZM16.5 8.5C16.5
            9.48491 16.306 10.4602 15.9291 11.3701C15.5522 12.2801 14.9997 13.1069 14.3033 13.8033C13.6069 14.4997
            12.7801 15.0522 11.8701 15.4291C10.9602 15.806 9.98491 16 9 16C8.01509 16 7.03982 15.806 6.12987
            15.4291C5.21993 15.0522 4.39314 14.4997 3.6967 13.8033C3.00026 13.1069 2.44781 12.2801 2.0709
            11.3701C1.69399 10.4602 1.5 9.48491 1.5 8.5C1.5 6.51088 2.29018 4.60322 3.6967 3.1967C5.10322
            1.79018 7.01088 1 9 1C10.9891 1 12.8968 1.79018 14.3033 3.1967C15.7098 4.60322 16.5 6.51088 16.5 8.5Z
          "
          :stroke="
            isActive ? $vuetify.theme.themes.light.primary.base : '#858F9D'
          "
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
    <template v-if="step.name == $t('product.steps.names.confirmation')">
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
            M6.5 8.5L8.16667 10.1667L11.5 6.83333M16.5 8.5C16.5 9.48491 16.306 10.4602 15.9291
            11.3701C15.5522 12.2801 14.9997 13.1069 14.3033 13.8033C13.6069 14.4997 12.7801 15.0522
            11.8701 15.4291C10.9602 15.806 9.98491 16 9 16C8.01509 16 7.03982 15.806 6.12987
            15.4291C5.21993 15.0522 4.39314 14.4997 3.6967 13.8033C3.00026 13.1069 2.44781 12.2801
            2.0709 11.3701C1.69399 10.4602 1.5 9.48491 1.5 8.5C1.5 6.51088 2.29018 4.60322 3.6967
            3.1967C5.10322 1.79018 7.01088 1 9 1C10.9891 1 12.8968 1.79018 14.3033 3.1967C15.7098
            4.60322 16.5 6.51088 16.5 8.5Z
          "
          :stroke="
            isActive ? $vuetify.theme.themes.light.primary.base : '#858F9D'
          "
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
    <template
      v-if="
        step.name === $t('product.steps.names.information') ||
          step.name === $t('product.steps.names.contact') ||
          step.name === $t('product.steps.names.traveller_details')
      "
    >
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="
            M3.2675 13.3367C5.01618 12.3483 6.99132 11.8303 9 11.8333C11.0833 11.8333
            13.0392 12.3792 14.7325 13.3367M11.5 6.83333C11.5 7.49637 11.2366 8.13226
            10.7678 8.6011C10.2989 9.06994 9.66304 9.33333 9 9.33333C8.33696 9.33333
            7.70107 9.06994 7.23223 8.6011C6.76339 8.13226 6.5 7.49637 6.5 6.83333C6.5
            6.17029 6.76339 5.53441 7.23223 5.06557C7.70107 4.59673 8.33696 4.33333 9
            4.33333C9.66304 4.33333 10.2989 4.59673 10.7678 5.06557C11.2366 5.53441 11.5
            6.17029 11.5 6.83333ZM16.5 8.5C16.5 9.48491 16.306 10.4602 15.9291 11.3701C15.5522
            12.2801 14.9997 13.1069 14.3033 13.8033C13.6069 14.4997 12.7801 15.0522 11.8701
            15.4291C10.9602 15.806 9.98491 16 9 16C8.01509 16 7.03982 15.806 6.12987 15.4291C5.21993
            15.0522 4.39314 14.4997 3.6967 13.8033C3.00026 13.1069 2.44781 12.2801 2.0709 11.3701C1.69399
            10.4602 1.5 9.48491 1.5 8.5C1.5 6.51088 2.29018 4.60322 3.6967 3.1967C5.10322 1.79018
            7.01088 1 9 1C10.9891 1 12.8968 1.79018 14.3033 3.1967C15.7098 4.60322 16.5 6.51088 16.5 8.5Z
          "
          :stroke="
            isActive ? $vuetify.theme.themes.light.primary.base : '#858F9D'
          "
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
    <template v-if="step.name == $t('product.steps.names.review_payment')">
      <svg
        width="18"
        height="15"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 5.83366H16.5H1.5ZM4.83333 10.0003H5.66667H4.83333ZM9 10.0003H9.83333H9ZM4 13.3337H14C14.663 13.3337 15.2989 13.0703 15.7678 12.6014C16.2366 12.1326 16.5 11.4967 16.5 10.8337V4.16699C16.5 3.50395 16.2366 2.86807 15.7678 2.39923C15.2989 1.93038 14.663 1.66699 14 1.66699H4C3.33696 1.66699 2.70107 1.93038 2.23223 2.39923C1.76339 2.86807 1.5 3.50395 1.5 4.16699V10.8337C1.5 11.4967 1.76339 12.1326 2.23223 12.6014C2.70107 13.0703 3.33696 13.3337 4 13.3337Z"
          :stroke="
            isActive ? $vuetify.theme.themes.light.primary.base : '#858F9D'
          "
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProgressIndicatorBooking',
  props: {
    step: {
      default: function() {
        return {
          status: 'PENDING',
          name: this.$t('product.steps.names.availability'),
        };
      },
      type: Object,
      validator: (val) => {
        return ['PENDING', 'DONE', 'ACTIVE'].indexOf(val.status) > -1;
      },
    },
  },
  computed: {
    isActive() {
      return this.step.status === 'ACTIVE';
    },
    isDone() {
      return this.step.status === 'DONE';
    },
    isActiveOrDone() {
      return this.step.isActive || this.isDone;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  width: 17px;
  height: 17px;
}
</style>
